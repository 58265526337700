.log-section h2 {
    text-decoration: underline;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  
  .log-box {
    background-color: #f0f0f0;
    border: 2px solid #ddd;
    height: 300px;
    overflow-y: auto; /* vertical scrolling */
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
  }
  
  .log-entry {
    margin-bottom: 10px;
  }
  