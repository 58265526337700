.header {
    text-align: center;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .header h1 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
  
  .callInfo {
    margin-top: 5px;
    font-size: 14px;
  }
  