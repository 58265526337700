.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar {
    left: 0;
    /* top: 0; */
    width: 100%;
    max-width: 370px;
    background-color: #f4f4f4;
    overflow-y: auto; /* vertical scroll */
    box-sizing: border-box;
  }
  
.unknown-user-prompt {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
}

.unknown-user-prompt a {
    color: #721c24;
    text-decoration: underline;
}

.unknown-user-prompt a:hover {
    color: #721c24;
    text-decoration: none;
}

.button-as-link {
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    font: inherit;
    cursor: pointer;
}

.button-as-link:hover {
  color: #0056b3;
}

.feedback-button {
  margin: 20px;
  text-align: center;
  background-color: white;
  width: 100%;
  max-width: 370px;
  color: #007bff;
}

.button-separator {
    margin: 0 10px; 
    color: #000;
    font-size: 18px;
  }
