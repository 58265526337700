.no-host-section {
  padding: 20px;
  margin: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  text-align: center;
}

.no-host-section h2 {
  color: #721c24;
}

.no-host-section p {
  font-size: 16px;
}
