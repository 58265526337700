.participants-section h2 {
    text-decoration: underline;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    font-size: 20px;
    font-weight: 450;
    text-align: left;
    margin-left: 40px;
    margin-top: -3px;
  }

  ul li {
    margin-bottom: 3px;
  }
  
  .participant {
    color: orange;
  }

  .verified {
    color: green;
  }
  
  .unverified {
    color: red;
    font-weight: bold;
  }

  .bot {
    color: gray;
    font-style: italic;
  }

  .participant-details summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    list-style: none;
  }

  .participant-details .preview {
    font-size: 0.9em;
    margin-left: 4px;
    display: flex;
    align-items: center;
  }
  
  .participant-details .dropdown-arrow {
    margin-right: 8px;
    font-size: 0.8em;
  }
  
  .participant-details .icon.small-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
  
  .icon-verified {
    filter: invert(44%) sepia(93%) saturate(1607%) hue-rotate(110deg) brightness(70%) contrast(92%); /* Green */
    font-weight: 400;
  }
  
  .icon-unverified {
    filter: invert(12%) sepia(74%) saturate(7491%) hue-rotate(340deg) brightness(90%) contrast(125%); /* Red */
    font-weight: 650;
  }
  
  .icon-unknown {
    filter: invert(56%) sepia(8%) saturate(748%) hue-rotate(190deg) brightness(90%) contrast(86%); /* Grey */
    font-weight: 400;
  }  

  .participant-details .details-content {
    margin-left: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
    color: black;
    font-size: 16px;
  }
  .participant-details .details-content .participant-statuses {
    padding: 5px 0 5px 0;
    margin-right: 10px;
    color: black;
    font-size: 18px;
  }

  .participant-statuses span {
    margin-left: 8px;
    font-size: 16px;
  }
  